<template src="./LastJobs.html">

</template>

<script>
import OfferThumbnail from "@/components/OfferThumbnail/OfferThumbnail";

export default {
  name: "LastJobs",
  props: {
    lastJobs: {
      type: Array
    }
  },
  components:{
    OfferThumbnail
  },
  data (){
    return {
      itemsToShow: Number(2),
    }
  },
  mounted() {
    window.addEventListener('resize', this.reportWindowSize);
    this.reportWindowSize();
  },
  methods:{
    /**
     * Responsive de las ofertas trabajo
     *
     * El numero de itemsToShow define cuantas ofertas se muestran
     */
    reportWindowSize () {
      if (window.innerWidth < 550){
        this.itemsToShow = Number(1);
      }
      else if (window.innerWidth < 1024){
        this.itemsToShow = Number(2);
      }
      else if (window.innerWidth < 1280){
        this.itemsToShow = Number(3);
      }
      else if (window.innerWidth > 1280){
        this.itemsToShow = Number(3);
      }
    }
  },
}
</script>

<style scoped lang="scss" src="./LastJobs.scss">

</style>
