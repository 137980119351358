<template src="./YourPublishedOffers.html">

</template>

<script>

import EmployerOfferThumbnail from "@/components/EmployerOfferThumbnail/EmployerOfferThumbnail";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "YourPublishedOffers",
  props: {
    offers: {
      type: Array
    }
  },
  components:{
    EmployerOfferThumbnail,
    ArrobaMedellinArrobitaNoData
  },
  data (){
    return {
      itemsToShow: Number(2),
    }
  },
  mounted() {
    window.addEventListener('resize', this.reportWindowSize);
    this.reportWindowSize();
  },
  methods:{
    reportWindowSize () {
      if (window.innerWidth < 550){
        this.itemsToShow = Number(1);
      }
      else if (window.innerWidth < 1224){
        this.itemsToShow = Number(2);
      }
      else if (window.innerWidth < 1580){
        this.itemsToShow = Number(3);
      }
      else if (window.innerWidth > 1580){
        this.itemsToShow = Number(3);
      }
    }
  },
}
</script>

<style scoped lang="scss" src="./YourPublishedOffers.scss">

</style>
