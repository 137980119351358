<template src="./ArrobaMedellinSeeker.html">
</template>

<script>
import SearchIcon from '@/components/Icons/SearchIcon';
export default {
  name: 'arroba-medellin-seeker',
  components: {
    SearchIcon
  },
  props: {
    title: String,
    errorMessage: Boolean
  },
  data () {
    return {
      searchWord: "",
    }
  },
  methods: {
    search() {
      this.$emit('searchExpression', this.searchWord);
    },
    searchClean() {
      this.$emit('searchExpression',  this.searchWord = "");
    }
  },
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinSeeker.scss">
</style>
