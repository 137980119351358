import { render, staticRenderFns } from "./ArrobaMedellinVerticalCard.html?vue&type=template&id=0a6ef7e0&scoped=true&"
import script from "./ArrobaMedellinVerticalCard.vue?vue&type=script&lang=js&"
export * from "./ArrobaMedellinVerticalCard.vue?vue&type=script&lang=js&"
import style0 from "./ArrobaMedellinVerticalCard.scss?vue&type=style&index=0&id=0a6ef7e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6ef7e0",
  null
  
)

export default component.exports