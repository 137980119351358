<template src="./OthersJobs.html"></template>

<script>
import { roleName } from "@/utils/constants";

export default {
  name: "OthersJobs",
  data () {
    return {
      roleName
    }
  },
  props:{
    data:{
      type: Array,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss" src="./OthersJobs.scss">

</style>
