<template src="./Job.html"></template>

<script>
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";
import { SwiperSlide } from "vue-awesome-swiper";
import OthersJobs from "@/components/OthersJobs/OthersJobs";
import LastJobs from "@/components/LastJobs/LastJobs";
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import YourPublishedOffers from "@/components/YourPublishedOffers/YourPublishedOffers";
import PublishedOffers from "@/components/PublishedOffers/PublishedOffers";
import ArrobaMedellinVerticalCard from "@/components/ArrobaMedellinVerticalCard/ArrobaMedellinVerticalCard";
import Accessibility from "@/components/SideMenuAccessibility/SideMenuAccessibility";
import swiperSmallOption from "@/utils/swiper-small-option";
import { mapActions, mapGetters } from "vuex";
import jobsService from "@/services/Jobs";
import { roleName } from "@/utils/constants";
import tutorial from "@/mixins/tutorial";
import { putFile } from "@/services/files";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import jobServices from "@/services/Jobs";

export default {
  name: "Job",
  mixins: [tutorial],
  components: {
    PublishedOffers,
    OthersJobs,
    LastJobs,
    SwiperSlide,
    YourPublishedOffers,
    ArrobaMedellinVerticalCard,
    Accessibility,
    ArrobaMedellinSeeker,
    UploadImageModal,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      enableCV: null,
      swiperSmallOption: swiperSmallOption,
      jobOffers: [],
      remarkableJobOffers: [],
      otherJobs: {},
      offers: [],
      lastJobs: [],
      errorMessage: false,
      roleName,
      role: null,
      file: null,
      title: "Tus procesos",
      massiveOffers: false,
      loading: false,
      loadCV: false,
      infoFile: {},
      modalUploadImageIsActive: false,
      CVListData: [],
    };
  },
  mounted() {
    this.role = this.user.role.name;
    if (
      this.role === "Student" ||
      this.role === "Teacher" ||
      this.role === "Entrepreneur" ||
      this.role === "TeacherInvestigator"
    ) {
      this.getJobs();
      this.fetchJobApplicationPhases();
      this.dataTutorial("job");
    }
    this.loadCV = this.$route.query.enableCV === "true" ? true : false;
    if (this.loadCV) {
      this.getAllCurriculum();
    }
  },
  methods: {
    async handleDeleteCv(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar hoja de vida',
        message: '¿Deseas eliminar esta hoja de vida?',
        type: ' is-primary is-outlined',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        size: 'is-small',
        onConfirm: async () => {
          try {
            await jobServices.deleteCurriculum(id);
            await this.getAllCurriculum();
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Hoja de vida eliminada exitosamente',
              position: 'is-bottom',
              type: 'is-success'
            })
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }
      })
    },
    editCV(id) {
      this.$router.push(`/NewCv?curriculumId=${id}`);
    },
    fileClick() {
      this.$refs["excel-upload-input"].click();
    },
    loadFile(e) {
      const files = e.target.files;
      if (!files) return;
      this.file = files[0];
    },
    async uploadFile() {
      try {
        const name = `${new Date().getTime()}-${this.file.name}`;
        await putFile(this.file, "jobOffers", name);
        await jobServices.createMassiveJobs({
          name: name,
        });
        this.getJobs();
        this.massiveOffers = false;
        this.$refs["excel-upload-input"].value = null;
        this.file = null;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Ofertas creadas correctamente",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        console.log("Error on load massive offers", error);
        this.$refs["excel-upload-input"].value = null;
        this.file = null;
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message ?? 'Ocurrio un error cargando las ofertas laborales, revisa el archivo',
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async getJobOfferSearch(word) {
      try {
        this.errorMessage = false;
        if (!word) await this.getJobs();
        else {
          const response = await jobsService.getJobOfferSearch(word);
          if (response.length === 0) this.errorMessage = true;
          this.jobOffers = response;
          this.offers = response;
          this.title = "Busqueda";
        }
      } catch (e) {
        console.error("Error getJobOfferSearch", e);
      }
    },
    async getAllCurriculum() {
      try {
        const resp = await jobsService.getAllCurriculum();
        this.CVListData = [];
        resp.forEach((curriculum) => {
          this.CVListData.push({
            name: curriculum.name,
            url: curriculum.url,
            id: curriculum.id,
            editable: curriculum.form ? true : false,
          });
        });
      } catch (e) {
        console.log("error", e);
      }
    },
    async getJobs() {
      try {
        if (this.role === this.roleName.ENTREPRENEUR) {
          const data = await jobsService.getJobs(this.user.id);
          this.jobOffers = data.jobOffers;
          this.remarkableJobOffers = data.remarkableJobOffers;
        } else {
          const data = await jobsService.getJobsStudent(this.user.id);
          this.offers = data.appliedJobOffers;
          this.title = "Tus procesos";
          this.remarkableJobOffers = data.recommendedOffers;
          this.lastJobs = data.openJobOffers;
        }
      } catch (e) {
        console.error("error", e);
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    getExtension(filename) {
      const parts = filename.split(".");
      return parts[parts.length - 1];
    },
    isTypePDF(filename) {
      const ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case "pdf":
          return true;
      }
      return false;
    },
    async updatePDFCV(file) {
      try {
        this.loadCV = true;
        this.infoFile = file;
        const url = await putFile(
          this.infoFile.file,
          "CV-PDF",
          `${this.infoFile.file.name}`
        );

        if (this.isTypePDF(this.infoFile.file.name)) {
          const newCurriculum = await jobsService.createdCurriculum({
            name: this.infoFile.file.name,
            url: url,
          });
          if (newCurriculum) {
            await this.getAllCurriculum();
          }
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Formato no soportado, formato PDF requerido",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    viewTutorial() {
      this.dataTutorial("job");
    },
    displayCVModal() {
      this.loadCV = true;
      this.getAllCurriculum();
      this.dataTutorial("modalJob");
    },
    ...mapActions({
      fetchJobApplicationPhases: "fetchJobApplicationPhases",
    }),
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    roleValidationNoAccess() {
      return [
        "Invited",
        "University",
        "AdministratorCall",
        "Administrator",
      ].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./Job.scss">

</style>
