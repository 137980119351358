const swiperSmallOption = {
  slidesPerView: 1,
  spaceBetween: 20,
  slidesPerGroup: 1,
  centeredSlides: true,
  breakpoints: {
    1216: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      centeredSlides: false
    },
    1024: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      centeredSlides: true
    },
    643: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      centeredSlides: false
    }
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }
};

export default swiperSmallOption;
